import React from 'react';
import logo from './logo.svg';
import './App.css';
import Nav from './components/nav/nav';
import Main from './pages/Main';
import Page2 from './pages/page2/Page2';
import Page3 from './pages/page3/Page3';
import NavSimple from './components/nav/NavSimple';
import AltMain from './pages/alt/Main';

function App() {
  return (
    <div>
     {/* <Nav /> */}
      <div className="App">
      <NavSimple />
      
        <AltMain />
        {/* <Main />
        <Page2 />
        <Page3 /> */}
      </div>
    </div>
  );
}

export default App;
