import React, { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';
import './Main.css';
import { analytics, app } from '../../firebase';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

const db = getFirestore(app);

const AltMain: React.FC = () => {
    const el2 = useRef(null);
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [city, setCity] = useState('');
    const [comments, setComments] = useState('');
    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const typed2 = useRef<Typed | null>(null);

    useEffect(() => {
        const options2 = {
            strings: ['Swiping', 'Waiting', 'Chatting'],
            typeSpeed: 70,
            backSpeed: 30,
            backDelay: 5000,
            startDelay: 1000,
            loop: true,
            showCursor: true,
            cursorChar: '|'
        };

        if (el2.current) {
            typed2.current = new Typed(el2.current, options2);
        }

        return () => {
            if (typed2.current) {
                typed2.current.destroy();
            }
        };
    }, []);

    const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const handleSubmit = async () => {
        if (isValidEmail(email)) {
            try {
                await addDoc(collection(db, 'emails'), {
                    email,
                    gender,
                    age,
                    city,
                    comments,
                    timestamp: new Date(),
                });
                setSubmissionSuccessful(true); // Set submissionSuccessful to true upon success
            } catch (error) {
                console.error('Error submitting data:', error);
                alert('Failed to submit data.');
            }
        } else {
            alert('Please enter a valid email address.');
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="s">
            <div className="main-container">
                <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="text-container">
                        <div className="typing-container">Spend Time Dating</div>
                        <div className="typing-container">Not <span ref={el2}></span></div>
                        <p className="description-text">
                            Rezurv is an AI-powered dating app that powers connection through real-world encounters, not swiping.
                            Enter your details to gain exclusive access.
                        </p>
                    </div>
                    {/* Conditionally render the form or thank-you message */}
                    {submissionSuccessful ? (
                        <div className="thank-you-message" style={{ marginTop: '20px' }}>
                            Thanks, and we'll be in touch!
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '300px', gap: '8px' }}>
                            {/* Gender and Age in one line */}
                            <div style={{ display: 'flex', gap: '8px', width: '100%', maxWidth: '300px'}}>
                                <input
                                    placeholder="Gender"
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    className="centered-input"
                                    style={{  width: '100%', boxSizing: 'border-box' }}
                                />
                                <input
                                    placeholder="Age"
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    type="number"
                                    className="centered-input"
                                    style={{  width: '100%', boxSizing: 'border-box' }}
                                />
                            </div>
                            {/* City */}
                            <input
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="centered-input"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                            />
                            {/* Email */}
                            <input
                                ref={inputRef}
                                onKeyDown={handleKeyDown}
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="centered-input"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                            />
                            {/* Comments - Single Line */}
                            <input
                                placeholder="Comments"
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                className="centered-input"
                                style={{ width: '100%', boxSizing: 'border-box' }}
                            />
                            <button onClick={handleSubmit} className="centered-button" style={{ width: '100%' }}>
                                Reserve
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AltMain;
