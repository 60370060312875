// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAi7E97IEfYz7URQ1Of2UTtdNQaQqE32w0",
  authDomain: "reserve-c4a15.firebaseapp.com",
  projectId: "reserve-c4a15",
  storageBucket: "reserve-c4a15.firebasestorage.app",
  messagingSenderId: "278521096515",
  appId: "1:278521096515:web:23ec3a3794f181fa35e7f6",
  measurementId: "G-XHYP8XPX6B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, app };