import React from 'react';
import '../../main.css';
import './navSimple.css';
const Nav: React.FC<{}> = ({ }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="nav">
        <img src={'/reserve.png'} alt="logo" width={88} />
    </nav>
  );
};

export default Nav;
